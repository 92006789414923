@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;700&family=Source+Sans+Pro&display=swap");

:root {
  --taupe: #7f6259;
  --periwinkle: #F0F2FA;
  --cadet: #343547;
  --rose: #c29191;
  --independence: #4b4e63;
}
html {
  font-size: 16px;
}
body {
  background-color: var(--periwinkle);
  color: var(--cadet);
  font-family: "Source Sans pro";
}
a, a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: var(--rose);
  cursor: pointer;
}

h1,
h2 {
  font-family: "Playfair Display";
  font-weight: 400;
}

h1 {
  font-size: 3rem;
  line-height: 4.5rem;
}

h2 {
  font-size: 2rem;
  line-height: 3.5rem;
  margin-top: auto;
  margin-bottom: auto;
}

bold {
  font-weight: 600;
}

.App {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.rose {
  color: var(--rose)
}

.landing-hero {
  margin-top: 5rem;
}

.picture {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}

#profile-pic {
  max-width: 80vw;
}

.container {
  display: flex;
  width: 100%;
}

.skills {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, 50%);
  grid-row-gap: 5rem;
  grid-column-gap: 1.5rem;
  margin-top: 8rem;
}

.text-right {
  text-align: right;
}

.skillset {
  color: var(--taupe);
  font-size: 1rem;
  line-height: 1.5rem;
}

.footer {
  grid-column: span 2 / span 2;
  color: var(--rose);
  margin: 10rem auto 1rem auto;
    font-family: "Playfair Display"
}

@media screen and (max-width: 580px) {
  html {
    font-size: 12px;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  .skillset {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}